<template>
    <footer class="footer">
        <div class="footer__top">
            <a href="#" class="footer__logo"><img src="../assets/images/logo.svg" alt="logo" width="182" height="28"></a>
            <ul class="appstore-list">
                <li class="appstore-list__item appstore"><a href="#"><img src="../assets/images/footer/appstore.svg" alt="appstore" width="138" height="38"></a></li>
                <li class="appstore-list__item googleplay"><a href="#"><img src="../assets/images/footer/googleplay.svg" alt="googleplay" width="131" height="38"></a></li>
            </ul>
            <nav class="footer__nav nav">
                <div class="nav__wrapper">
                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Автовладельцам</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Все марки авто</a></li>
                            <li class="nav__item"><a href="#">Почему ремонт у нас дешевле</a></li>
                            <li class="nav__item">Все сервисы<a href="#"></a></li>
                            <li class="nav__item">Советы по ремонту авто<a href="#"></a></li>
                        </ul>
                    </div>

                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Партнёрские программы</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Подключить автосервис</a></li>
                            <li class="nav__item"><a href="#">Поставщикам запчастей</a></li>
                            <li class="nav__item"><a href="#">Автомобильным сайтам</a></li>
                        </ul>
                    </div>

                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Информация</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Обслуживание автопарков</a></li>
                            <li class="nav__item"><a href="#">Пользовательское соглашение <br> Для СМИ</a></li>
                        </ul>
                    </div>

                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Сотрудничество</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Открыть “В-Автосервис” в своём <br> городе</a></li>
                        </ul>
                    </div>

                    <a href="mailto:info@v-avtoservis.ru" class="mail-link">info@v-avtoservis.ru</a>
                </div>
            </nav>
        </div>
        <div class="footer__bottom">
            <div class="footer__bottom-wrapper">
                <ul class="footer__bottom-list">
                    <li><a href="#"><img src="../assets/images/flag-ukr.png" alt="ukr" width="25" height="25"></a></li>
                    <li><a href="#"><img src="../assets/images/flag-bel.png" alt="bel" width="25" height="25"></a></li>
                    <li><a href="#"><img src="../assets/images/flag-kaz.png" alt="kaz" width="25" height="25"></a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>