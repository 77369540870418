<template>
    <v-carousel
        cycle
        height="auto"
        hide-delimiter-background
        show-arrows-on-hover
>
    <template v-slot:prev="{ on, attrs }">
        <v-btn
                color="success"
                v-bind="attrs"
                v-on="on"
                class="carousel-btn"
        >Предыдущий</v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
        <v-btn
                color="info"
                v-bind="attrs"
                v-on="on"
                class="carousel-btn"
        >Следующий</v-btn>
    </template>
    <v-carousel-item>
        <div class="comment__chapter owl-carousel owl-theme" id="commentCarousel">
            <div class="comment__wrapper comment-one">
                <header class="comment__header">
                    <h3 class="comment__name"><span>От</span>Пётр Смирнов</h3>
                    <div class="comment__stars">
                        <div class="comment__stars-small">5<img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14"></div>
                        <div class="comment__stars-big">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/starempty.svg" alt="star" width="14" height="14">
                        </div>
                    </div>
                    <div class="comment__date">06-02-2020 15:02</div>
                </header>
                <ul class="comment__list">
                    <li class="comment__item">
                        <div class="comment__service">Авто:</div>
                        <div class="comment__brand">Volkswagen Golf V</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Сервис:</div>
                        <div class="comment__brand">Авто Моторз на Тишинке</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Услуга:</div>
                        <div class="comment__brand">Стёкла, фары <span><img src="../assets/../assets/images/comment-arrow.svg" alt="arrow" width="11" height="6"></span> Замена лобового стекла</div>
                    </li>
                </ul>
                <section class="comment__text">
                    <h2 class="visually-hidden">Отзыв</h2>
                    <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым... <button class="comment__open">читать всё</button><span>для визуально-слухового восприятия</span></p>
                </section>
                <div class="comment__audio audio">
                    <div class="audio__icon"></div>
                    <div class="audio__line"></div>
                    <p class="audio__time">0:15 / 0:52</p>
                </div>
            </div>
            <div class="comment__wrapper comment-two">
                <header class="comment__header">
                    <h3 class="comment__name">Джеймс Бонд</h3>
                    <div class="comment__stars">
                        <div class="comment__stars-small">5<img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14"></div>
                        <div class="comment__stars-big">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/starempty.svg" alt="star" width="14" height="14">
                        </div>
                    </div>
                    <div class="comment__date">06-02-2020 15:02</div>
                </header>
                <ul class="comment__list">
                    <li class="comment__item">
                        <div class="comment__service">Авто:</div>
                        <div class="comment__brand">Volkswagen Golf V</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Сервис:</div>
                        <div class="comment__brand">Авто Моторз на Тишинке</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Услуга:</div>
                        <div class="comment__brand">Стёкла, фары <span><img src="../assets/../assets/images/comment-arrow.svg" alt="arrow" width="11" height="6"></span> Замена лобового стекла</div>
                    </li>
                </ul>
                <section class="comment__text">
                    <h2 class="visually-hidden">Отзыв</h2>
                    <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым... <button class="comment__open">читать всё</button><span>для визуально-слухового восприятия</span></p>
                </section>
                <div class="comment__audio audio">
                    <div class="audio__icon"></div>
                    <div class="audio__line"></div>
                    <p class="audio__time">0:15 / 0:52</p>
                </div>
            </div>
            <div class="comment__wrapper comment-three">
                <header class="comment__header">
                    <h3 class="comment__name">Дональд Трамп</h3>
                    <div class="comment__stars">
                        <div class="comment__stars-small">5<img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14"></div>
                        <div class="comment__stars-big">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/starempty.svg" alt="star" width="14" height="14">
                        </div>
                    </div>
                    <div class="comment__date">06-02-2020 15:02</div>
                </header>
                <ul class="comment__list">
                    <li class="comment__item">
                        <div class="comment__service">Авто:</div>
                        <div class="comment__brand">Volkswagen Golf V</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Сервис:</div>
                        <div class="comment__brand">Авто Моторз на Тишинке</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Услуга:</div>
                        <div class="comment__brand">Стёкла, фары <span><img src="../assets/../assets/images/comment-arrow.svg" alt="arrow" width="11" height="6"></span> Замена лобового стекла</div>
                    </li>
                </ul>
                <section class="comment__text">
                    <h2 class="visually-hidden">Отзыв</h2>
                    <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым... <button class="comment__open">читать всё</button><span>для визуально-слухового восприятия</span></p>
                </section>
                <div class="comment__audio audio">
                    <div class="audio__icon"></div>
                    <div class="audio__line"></div>
                    <p class="audio__time">0:15 / 0:52</p>
                </div>
            </div>
        </div>
    </v-carousel-item>
    <v-carousel-item>
        <div class="comment__wrapper comment-three">
            <header class="comment__header">
                <h3 class="comment__name">Дональд Трамп</h3>
                <div class="comment__stars">
                    <div class="comment__stars-small">5<img src="../assets/images/star.svg" alt="star" width="14" height="14"></div>
                    <div class="comment__stars-big">
                        <img src="../assets/images/star.svg" alt="star" width="14" height="14">
                        <img src="../assets/images/star.svg" alt="star" width="14" height="14">
                        <img src="../assets/images/star.svg" alt="star" width="14" height="14">
                        <img src="../assets/images/star.svg" alt="star" width="14" height="14">
                        <img src="../assets/images/starempty.svg" alt="star" width="14" height="14">
                    </div>
                </div>
                <div class="comment__date">06-02-2020 15:02</div>
            </header>
            <ul class="comment__list">
                <li class="comment__item">
                    <div class="comment__service">Авто:</div>
                    <div class="comment__brand">Volkswagen Golf V</div>
                </li>
                <li class="comment__item">
                    <div class="comment__service">Сервис:</div>
                    <div class="comment__brand">Авто Моторз на Тишинке</div>
                </li>
                <li class="comment__item">
                    <div class="comment__service">Услуга:</div>
                    <div class="comment__brand">Стёкла, фары <span><img src="../assets/images/comment-arrow.svg" alt="arrow" width="11" height="6"></span> Замена лобового стекла</div>
                </li>
            </ul>
            <section class="comment__text">
                <h2 class="visually-hidden">Отзыв</h2>
                <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым... <button class="comment__open">читать всё</button><span>для визуально-слухового восприятия</span></p>
            </section>
            <div class="comment__audio audio">
                <div class="audio__icon"></div>
                <div class="audio__line"></div>
                <p class="audio__time">0:15 / 0:52</p>
            </div>
        </div>
    </v-carousel-item>
</v-carousel>
</template>

<script>
    export default {
        name: "Carousel",
        data () {
            return {
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth',
                ],
            }
        },
    }
</script>

<style scoped>
    .carousel-btn {
    }
</style>