<template>
  <v-container>
    <section class="offer">
      <div class="offer__wrapper">
        <div class="offer__text">
          <h2 class="offer__title">Подберём лучший автосервис <br> за 7 минут</h2>
          <p>Это бесплатно</p>
        </div>
        <div class="index__menu">
          <ul class="index__list">
            <li><a href="#">Все</a></li>
            <li><router-link :to="{name: 'diagnostika', params: {name: 'Диагностика'}}" >Диагностика</router-link></li>
            <li><router-link :to="{name: 'Service', params: {name: 'Техническое обслуживание'}}">Техническое обслуживание</router-link></li>
            <li><a href="#">Тормозная система</a></li>
            <li><a href="#">Трансмиссия</a></li>
            <li><a href="#">Подвеска</a></li>
            <li><a href="#">Электрика</a></li>
            <li class="index__another">
              <a href="#">Другое<span class="index__another-arrow"><svg width="10" height="5" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L8 6L15 1" stroke="#DB4661" stroke-width="1.5" stroke-linecap="round"/>
							</svg>
							</span></a>
              <ul class="index__another-list">
                <li><a href="#">Другое</a></li>
                <li><a href="#">Другое</a></li>
                <li><a href="#">Другое</a></li>
                <li><a href="#">Другое</a></li>
                <li><a href="#">Другое</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="process">
      <div class="process__wrapper">
        <h2 class="section-title">Как проходит ремонт</h2>
        <ul class="process__list">
          <li class="process__item">
            <div class="process__icon">
              <img src="../assets/images/ticks.svg" alt="ticks" width="24" height="21">
            </div>
            <p>Заполняете и оставляете заявку. Всего пару кликов и Вы у цели!</p>
          </li>
          <li class="process__item">
            <div class="process__icon">
              <img src="../assets/images/lines.svg" alt="lines" width="24" height="20">
            </div>
            <p>За 7 минут мы подберём варинаты ремонта по марке, модели, цене и району города</p>
          </li>
          <li class="process__item">
            <div class="process__icon">
              <img src="../assets/images/tools.svg" alt="tools" width="24" height="24">
            </div>
            <p>Приезжаете в сервис. Цена не изменится, а мы проследим за качеством.</p>
          </li>
        </ul>
      </div>
    </section>
    <section v-if="1 === ''" class="form-section">
      <div class="form-section__wrapper">
        <h2 class="section-title">Подберём сервис за 7 минут. Просто заполни форму</h2>
        <form action="#" class="form">
          <div class="form__input-wrap">
            <div class="input__wrap"><input type="text" placeholder="Имя"></div>
            <div class="input__wrap"><input type="text" placeholder="+7 (___) ___-__-__"></div>
            <div class="input__wrap input__wrap-arrow">
              <input type="text" placeholder="Марка" class="input__arrow">
              <span><img src="../assets/images/arrow-form.svg" alt="arrow" width="12" height="6"></span>
            </div>
            <div class="input__wrap input__wrap-arrow">
              <input type="text" placeholder="Модель" class="input__arrow">
              <span><img src="../assets/images/arrow-form.svg" alt="arrow" width="12" height="6"></span>
            </div>
          </div>
          <div class="input__wrap"><textarea placeholder="Опишите проблему?"></textarea></div>
          <label class="agree__input">
            <input type="checkbox">
            <span class="agree__checkbox"></span>
            <span class="agree__text">Я соглашаюсь с <span class="agree__blue">условиями обработки персональных данных</span></span>
          </label>
          <button class="form-btn">Получите предложения СТО</button>
        </form>
      </div>
    </section>
    <section class="form-section">
      <div class="form-section__wrapper">
        <h2 class="section-title">Подберём сервис за 7 минут. Просто заполни форму</h2>
        <Form/>
      </div>
    </section>
    <section v-if="1 === ''" class="form-three-section">
      <div class="form-three__wrapper">
        <h2 class="section-title">Проверенный автосервис с лучшей ценой найдется за 7 минут</h2>
        <form action="#" class="form-three">
          <div class="form-three__input-wrapper">
            <textarea name="textarea" placeholder="Задайте вопрос"></textarea>
            <input type="text" placeholder="Имя">
            <input type="text" placeholder="Телефон">
            <button>Получить предложения СТО</button>
          </div>
        </form>
      </div>
    </section>
    <article class="comment">
      <div class="comment__add-wrapper">
        <h2 class="section-title">Истории наших клиентов</h2>
        <a href="#" class="comment__add">добавить свою</a>
      </div>
      <Carousel/>
    </article>
    <section class="stocks">
      <h2 class="section-title">Акции для любимых клиентов</h2>
      <div class="stocks__chapter owl-carousel owl-theme" id="stocksCarousel">
        <div class="stocks__wrapper stocks__wrapper-one">
          <h3 class="stocks__title">Подарок: 3 л масла при ремонте двигателя на сумму от 20 000 руб</h3>
          <p class="stocks__condition">действует до 01.01.2019</p>
        </div>
        <div class="stocks__wrapper stocks__wrapper-two">
          <h3 class="stocks__title">Легендарная тормозная система Brembo всего за 23 500 руб</h3>
          <p class="stocks__condition">действует до 01.01.2019</p>
        </div>
        <div class="stocks__wrapper stocks__wrapper-three">
          <h3 class="stocks__title">Скидка 20% на любую оклейку авто плёнкой Brandname</h3>
          <p class="stocks__condition">действует до 01.01.2019</p>
        </div>
      </div>
    </section>
    <section class="choose">
      <h3 class="visually-hidden">Выбор ремонта</h3>
      <div class="choose__wrapper">
        <section class="choose__category category">
          <h2 class="section-title">Выбрать по категории</h2>
          <div class="category__wrapper">
            <div class="category__input-wrapper">
              <div class="category__input">
                <input type="text" placeholder="какой ремонт вам нужен?">
                <span><img src="../assets/images/search.svg" alt="search" width="18" height="18"></span>
              </div>
            </div>
            <ul class="category__list">
              <li class="category__item">
                <router-link to="/diagnostika">
                  <div class="category__img"><img src="../assets/images/category-1.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Диагностика</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Техническое обслуживание'}}">
                  <div class="category__img"><img src="../assets/images/category-2.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Техническое обслуживание</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Сход/Развал'}}">
                  <div class="category__img"><img src="../assets/images/category-3.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Проверка сход/развала</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Тормозная система'}}">
                  <div class="category__img"><img src="../assets/images/category-4.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Тормозная система</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Кузовные работы'}}">
                  <div class="category__img"><img src="../assets/images/category-5.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Кузовные работы</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Трансмиссия'}}">
                  <div class="category__img"><img src="../assets/images/category-6.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Трансмиссия</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Услуги с выездом'}}">
                  <div class="category__img"><img src="../assets/images/category-7.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Услуги с выездом</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Подвеска'}}">
                  <div class="category__img"><img src="../assets/images/category-8.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Подвеска</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Защитные плёнки'}}">
                  <div class="category__img"><img src="../assets/images/category-9.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Защитные плёнки</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Дубликаты ключей'}}">
                  <div class="category__img"><img src="../assets/images/category-10.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Дубликаты ключей</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Стёкла, фары'}}">
                  <div class="category__img"><img src="../assets/images/category-11.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Стёкла, фары</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Техническое обслуживание'}}">
                  <div class="category__img"><img src="../assets/images/category-12.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Техническое обслуживание</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Проверка сход/развала'}}">
                  <div class="category__img"><img src="../assets/images/category-13.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Проверка сход/развала</h3>
                </router-link>
              </li>
              <li class="category__item">
                <router-link :to="{name: 'Service', params: {name: 'Тормозная система'}}">
                  <div class="category__img"><img src="../assets/images/category-14.svg" alt="item" width="32" height="32"></div>
                  <h3 class="category__title">Тормозная система</h3>
                </router-link>
              </li>
            </ul>
          </div>
        </section>
        <section class="choose__brand brand">
          <h2 class="section-title">Выбрать по марке</h2>
          <div class="brand__wrapper brand__wrapper-one">
            <div class="brand__input">
              <Search/>
              <span><img src="../assets/images/search.svg" alt="search" width="18" height="18"></span>
            </div>
            <div class="brand__list-wrapper">
              <div class="brand__list owl-carousel owl-theme" id="brandCarousel">
                <div class="brand__item-wrapper">
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                </div>
                <div class="brand__item-wrapper">
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                </div>
                <div class="brand__item-wrapper">
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                  <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="brand__wrapper brand__wrapper-two">
            <div class="brand__input">
              <Search/>
              <span><img src="../assets/images/search.svg" alt="search" width="18" height="18"></span>
            </div>
            <div class="brand__list-wrapper">
              <button class="brand__arrow brand__arrow-prw"><img src="../assets/images/arrow-prw.png" alt="arrow" width="32" height="12"></button>
              <button class="brand__arrow brand__arrow-nxt"><img src="../assets/images/arrow-nxt.png" alt="arrow" width="32" height="12"></button>
              <div class="brand__list brand__list-two">
                <div class="brand__list-box">
                  <div class="brand__list-big-wrapper brand__list-big-wrapper-one">
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                  </div>
                  <div class="brand__list-big-wrapper">
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                  </div>
                  <div class="brand__list-big-wrapper">
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-1.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-2.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-3.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-4.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-5.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-6.png" alt="brand" width="60" height="40"></a></div>
                    <div class="brand__item"><a href="#"><img src="../assets/images/brand-7.png" alt="brand" width="60" height="40"></a></div>
                  </div>
                </div>
              </div>
              <div class="comment__label-wrapper">
                <button class="comment__label comment__label-bg" id="brandBtnOne"></button>
                <button class="comment__label" id="brandBtnTwo"></button>
                <button class="comment__label" id="brandBtnThree"></button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <notice
            v-if="notice.isPopup === 1"
            @removePopup="removePopup"
            :isPopup="notice.isPopup"
            :title="notice.title"
            :subtitle="notice.subtitle"
    />
  </v-container>
</template>

<script>
  // import Inputmask from 'inputmask'
  //import axios from 'axios'
  import notice from './notification'
  import Carousel from './Carousel'
  import vForm from './form'
  import Search from './Search'
  export default {
    name: 'Home',
    components: {
      notice,
      'Form': vForm,
      Carousel,
      Search
    },
    data: () => ({
      notice: {
        isPopup: null,
        title: null,
        subtitle: null,
      },
    }),
    methods: {
      removePopup () {
        this.notice.isPopup = false
      }
    },
    mounted() {
      // Inputmask().mask(document.querySelectorAll('input[type=text]'))
    }
  }
</script>
<style>
  @import '../assets/css/index2.css';
  .form-btn.privacyPolicy {
    background: #bbcfff;
  }
  .error-send {
    background-color: rgba(255,0,0, 0.5);
    -webkit-animation-name: error-send;
    -webkit-animation-duration: 0.8s;
    -webkit-transform-origin:50% 50%;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  }
  @-webkit-keyframes error-send {
    9% {
      -webkit-transform:translateX(5px) rotate(3deg);
      transform:translateX(5px) rotate(3deg)
    }
    19% {
      -webkit-transform:translateX(-3px) rotate(-2deg);
      transform:translateX(-3px) rotate(-3deg)
    }
    29% {
      -webkit-transform:translateX(3px) rotate(2deg);
      transform:translateX(3px) rotate(2deg)
    }
    40% {
      -webkit-transform:translateX(-3px) rotate(-2deg);
      transform:translateX(-3px) rotate(-2deg)
    }
    50% {
      -webkit-transform:translateX(2px) rotate(1deg);
      transform:translateX(2px) rotate(1deg)
    }
    58% {
      -webkit-transform:translateX(-2px) rotate(-1deg);
      transform:translateX(-2px) rotate(-1deg)
    }
    67% {
      -webkit-transform:translateX(2px) rotate(1deg);
      transform:translateX(2px) rotate(1deg)
    }
    79% {
      -webkit-transform:translateX(-2px) rotate(-1deg);
      transform:translateX(-2px) rotate(-1deg)
    }
    89% {
      -webkit-transform:translateX(1px) rotate(0);
      transform:translateX(1px) rotate(0)
    }
    99% {
      -webkit-transform:translateX(-1px) rotate(0);
      transform:translateX(-1px) rotate(0)
    }
  }
</style>
