<template>
  <header class="header">
<!--    <div class="header__top-wrapper">-->
<!--      <div class="header__top">-->
<!--        <p class="header__slogan">Болеем за наших!</p>-->
<!--        <a href="#" class="olymp-link">Олимпиада 2020</a>-->
<!--      </div>-->
<!--    </div>-->
    <div class="header__bottom-wrapper">
      <div class="header__bottom">
        <div class="header__menu-wrapper">
          <button class="menu-btn">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div class="header__menu">
            <div class="town">
              <a @click="notice.isPopup = 11">{{ `${client.clientCity}${client.clientRegion ? ', ' + client.clientRegion : ''}` }}</a>
            </div>
            <ul class="reg-list">
              <li class="reg-list__item signup-open"><a href="#">Регистрация</a></li>
              <li class="reg-list__item signin-open"><a href="#">Вход</a></li>
            </ul>
            <a href="#" class="trust-link">Почему ремонт доверяют нам?</a>
            <div class="calc-link">
              <a href="#">
                <span>Рассчитать ремонт</span>
              </a>
            </div>
            <div class="phone-wrap">
              <div class="phone-icons">
                <a href="#" class="whatsapp">
                  <img src="../assets/images/icon-whatsapp.svg" alt="whatsapp" width="28" height="28">
                  <span class="visually-hidden">whatsapp</span>
                </a>
                <a href="#" class="viber">
                  <img src="../assets/images/icon-viber.svg" alt="viber" width="28" height="28">
                  <span class="visually-hidden">viber</span>
                </a>
              </div>
              <a href="tel:88001234567" class="tel">8 800 123-45-67</a>
            </div>
          </div>
        </div>
        <div class="header__left">
          <router-link to="/" class="header__menu-logo"><img src="../assets/images/logo.svg" alt="logo" width="155" height="24"></router-link>
          <div class="town town-big">
            <a @click="notice.isPopup = 11">
              {{ `${client.clientCity ? client.clientCity : 'Нажмите, чтобы указать город'}
              ${client.clientRegion ? ', ' + client.clientRegion : ''}` }}
            </a>
          </div>
        </div>
        <div class="header__right">
          <div class="user">
            <div class="user__enter">
              <a href="#" class="login-link signin-open">Войти</a>
            </div>
            <ul class="enter-list">
              <li class="enter-list__item">
                <a href="#">
                  <span class="enter-list__number">1</span>
                  <img src="../assets/images/icon-notif.svg" alt="notif" width="18" height="18">
                </a>
              </li>
              <li class="enter-list__item">
                <a href="#">
                  <span class="enter-list__number">4</span>
                  <img src="../assets/images/icon-message.svg" alt="message" width="18" height="18">
                </a>
              </li>
              <li class="enter-list__item enter-list__close"><a href="#">Выйти</a></li>
            </ul>
          </div>
          <div class="count-link"><a href="#yaFrom"><span class="count-link__logo"><img src="../assets/images/icon-calc2.svg" alt="calculate" width="16" height="16"></span><span class="count-link__text">Рассчитать ремонт</span></a></div>
        </div>
      </div>
      <section class="breadcrumbs" v-if="$route.path !== '/'">
        <h3 class="visually-hidden">Навигация по сайту</h3>
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item"><a href="#">Главная</a></li>
          <li class="breadcrumbs__item"><a href="#">{{$route.params.name}}</a></li>
        </ul>
      </section>
    </div>
    <notice
            v-if="notice.isPopup === 11"
            @removePopup="removePopup"
            @setClientCity="setClientCity"
            :isPopup="notice.isPopup"
            :title="notice.title"
            :subtitle="notice.subtitle?notice.subtitle:''"
    />
  </header>
</template>

<script>
  // import axios from "axios";
  import notice from "./notification";
  export default {
    name: 'Header',
    components: { notice },
    data: () => ({
      client: {
        clientCity: null,
        clientRegion: null
      },
      notice: {
        isPopup: null,
        title: null,
        subtitle: null,
      },
      geocoder: null
    }),
    methods: {
      getCity () {
        if (localStorage.clientCity) {
          this.client.clientCity = localStorage.clientCity
          this.client.clientRegion = localStorage.clientRegion
          this.$route.params.clientCity = localStorage.clientCity
          this.notice.title = 'Ваш город ' + localStorage.clientCity
          this.notice.subtitle = localStorage.clientRegion? 'Ваш регион ' + localStorage.clientRegion : null
        } else {
          navigator.geolocation.getCurrentPosition((position) => {
            // eslint-disable-next-line
            var city
            if (typeof google === 'undefined') window.location = '/'
            // eslint-disable-next-line
            this.geocoder = new google.maps.Geocoder()
            // eslint-disable-next-line
            this.geocoder.geocode({
              'location': {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
            }, (results, status) => {
              localStorage.clientCity = results[0].address_components[2].long_name
              localStorage.clientRegion = results[0].address_components[4].long_name
              this.$route.params.clientCity = results[0].address_components[2].long_name
              this.client.clientCity = results[0].address_components[2].long_name
              this.client.clientRegion = results[0].address_components[4].long_name
              this.notice.title = `Ваш город ${this.client.clientCity}`
              this.notice.subtitle = `Ваш регион ${this.client.clientRegion}`
              this.notice.isPopup = 11 // чтоб показать попап notice
              console.log(status)
            })
          }, error => {
            if (error) {
              this.notice.title = `Ваш город Москва`
              this.$route.params.clientCity = 'Москва'
              this.client.clientCity = 'Москва'
              localStorage.clientCity = this.client.clientCity
              this.notice.isPopup = 11 // чтоб показать попап notice
            }
          })
        }
      },
      setClientCity (payload) {
        this.client.clientCity = payload // payload это clientCity из компонента (новый введеный в инпуте город)
        this.$route.params.clientCity = this.client.clientCity
        this.removePopup() // закрываем окно вызвом функцию removePopup()
        this.notice.title = payload
        this.notice.subtitle = ''
        localStorage.clientCity = payload
        localStorage.clientRegion = ''
      },
      removePopup () {
        this.notice.isPopup = -1 // закрываем попап
      },
    },
    mounted() {
      this.getCity()
    }
  }
</script>
<style>
</style>
