<template>
  <v-app>
    <vHeader/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
  import vHeader from './components/v-header'
  import Footer from './components/Footer'
export default {
  name: 'App',
  components: {
    vHeader,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
<style>
  @import "./assets/css/normalize.css";
  @import "./assets/libs/owl-carousel2/assets/owl.carousel.min.css";
  @import "./assets/libs/owl-carousel2/assets/owl.theme.default.min.css";
  @import "./assets/css/main.css";
</style>
