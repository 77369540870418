<template>
    <div>
        <section class="offer offer-service">
            <div class="offer__wrapper offer__wrapper-service">
                <div class="offer__text offer__text-service">
                    <h2 class="offer__title">Замена тормозных колодок</h2>
                    <div class="offer__service-p">Подберём лучший сервис по замене колодок</div>
                </div>
            </div>
        </section>

        <section class="form-section">
            <div class="form-section__wrapper">
                <h2 class="section-title">Подберём сервис за 7 минут. Просто заполни форму</h2>\
                <Form
                />
            </div>
        </section>

        <section class="service-shop">
            <header class="service-shop__header">
                <h2 class="section-title">Предложения с фиксированными ценами</h2>
                <div class="service-shop__choose">
                    <span>По цене:</span>
                    <div class="service-shop__btn-toggle">
                        <button class="block service-shop-toggle service-cheap">дешевле</button>
                        <button class="service-shop-toggle service-expensive">дороже</button>
                        <div class="service-shop__btn-wrapper">
                            <button class="service-shop__cheap">дешевле</button>
                            <button class="service-shop__expensive">дороже</button>
                        </div>
                    </div>
                </div>
            </header>
            <div class="service-shop__wrapper">
                <div class="service-shop__list">
                    <div class="service-shop__item">
                        <div class="service-shop__left">
                            <div class="service-shop__title-wrapper">
                                <h3 class="service-shop__title">Диагностика</h3>
                                <h3 class="service-shop__title">Диагностика кондиционера</h3>
                            </div>
                            <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                        </div>
                        <div class="service-shop__right">
                            <div class="service-shop__price">500 руб</div>
                            <a href="#" class="service-shop__link entry-link">Записаться</a>
                        </div>
                    </div>

                    <div class="service-shop__item">
                        <div class="service-shop__left">
                            <div class="service-shop__title-wrapper">
                                <h3 class="service-shop__title">Тормозная система</h3>
                                <h3 class="service-shop__title">Замена тормозных колодок</h3>
                            </div>
                            <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                        </div>
                        <div class="service-shop__right">
                            <div class="service-shop__price">1200 руб</div>
                            <a href="#" class="service-shop__link entry-link">Записаться</a>
                        </div>
                    </div>

                    <div class="service-shop__item">
                        <div class="service-shop__left">
                            <div class="service-shop__title-wrapper">
                                <h3 class="service-shop__title">Стёкла, фары</h3>
                                <h3 class="service-shop__title">Замена заднего стекла</h3>
                            </div>
                            <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                        </div>
                        <div class="service-shop__right">
                            <div class="service-shop__price">1800 руб</div>
                            <a href="#" class="service-shop__link entry-link">Записаться</a>
                        </div>
                    </div>

                    <div class="service-shop__big">
                        <div class="service-shop__item">
                            <div class="service-shop__left">
                                <div class="service-shop__title-wrapper">
                                    <h3 class="service-shop__title">Диагностика</h3>
                                    <h3 class="service-shop__title">Диагностика кондиционера</h3>
                                </div>
                                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                            </div>
                            <div class="service-shop__right">
                                <div class="service-shop__price">500 руб</div>
                                <a href="#" class="service-shop__link entry-link">Записаться</a>
                            </div>
                        </div>

                        <div class="service-shop__item">
                            <div class="service-shop__left">
                                <div class="service-shop__title-wrapper">
                                    <h3 class="service-shop__title">Тормозная система</h3>
                                    <h3 class="service-shop__title">Замена тормозных колодок</h3>
                                </div>
                                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                            </div>
                            <div class="service-shop__right">
                                <div class="service-shop__price">1200 руб</div>
                                <a href="#" class="service-shop__link entry-link">Записаться</a>
                            </div>
                        </div>

                        <div class="service-shop__item">
                            <div class="service-shop__left">
                                <div class="service-shop__title-wrapper">
                                    <h3 class="service-shop__title">Стёкла, фары</h3>
                                    <h3 class="service-shop__title">Замена заднего стекла</h3>
                                </div>
                                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                            </div>
                            <div class="service-shop__right">
                                <div class="service-shop__price">1800 руб</div>
                                <a href="#" class="service-shop__link entry-link">Записаться</a>
                            </div>
                        </div>

                        <div class="service-shop__item">
                            <div class="service-shop__left">
                                <div class="service-shop__title-wrapper">
                                    <h3 class="service-shop__title">Диагностика</h3>
                                    <h3 class="service-shop__title">Диагностика кондиционера</h3>
                                </div>
                                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                            </div>
                            <div class="service-shop__right">
                                <div class="service-shop__price">500 руб</div>
                                <a href="#" class="service-shop__link entry-link">Записаться</a>
                            </div>
                        </div>

                        <div class="service-shop__item">
                            <div class="service-shop__left">
                                <div class="service-shop__title-wrapper">
                                    <h3 class="service-shop__title">Тормозная система</h3>
                                    <h3 class="service-shop__title">Замена тормозных колодок</h3>
                                </div>
                                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
                            </div>
                            <div class="service-shop__right">
                                <div class="service-shop__price">1200 руб</div>
                                <a href="#" class="service-shop__link entry-link">Записаться</a>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" class="service__shop-all"><span>Получить </span>Все предложения</a>
            </div>
        </section>

        <section class="service-info">
            <div class="service-info__wrapper">
                <div class="service-info__text">
                    <h3 class="service-info__title">501 автосервис на одном агрегаторе</h3>
                    <p>Агрегатор записи автомобилей в СТО в-автосервис.рф подключил 501 автосервис к своей платформе. Еще больше возможностей получают автовладельцы, которые обращаются для того чтобы посчитать стоимость покраски автомобиля, записаться на установку ГБО, записаться на шиномонтаж или выполнить любую другую работу по обслуживанию своего авто.</p>
                    <p>Ремонт Вашего автомобиля это теперь забота агрегатора, который подбирает СТО, предлагает варианты стоимости ремонта, записывает Вас на удобное время в том районе города в котором Вы хотите произвести обслуживание авто или его ремонт.</p>
                    <p>Еще больше акций ждут наших клиентов в ближайшее время. Больше партнерских программ с банками, торговыми сетями, еще больше подарков в магазине подарков и приятных сюрпризов, включая повышенный кэш-бэк за каждый ремонт.</p>
                    <h3 class="service-info__title">В-автосервис.рф расширяет свою географию</h3>
                    <p>В-автосервис.рф расширяет свою географию – записаться на ремонт Вы можете практически в любом городе России, а если не нашли своего города – звоните на нашу горячую линию и мы Вам обязательно поможем.</p>
                    <p>В-автосервис.рф – партнер Русского АвтоМото Клуба и вместе с компанией Автоюрист поддерживает начинающих водителей. Более подробно о программе мы расскажем в нашей ленте.</p>
                </div>
                <div class="service-info__img">
                    <img src="../../assets/images/service-phone.png" alt="phone" width="187" height="180">
                </div>
            </div>
        </section>

        <article class="comment">
            <div class="comment__add-wrapper">
                <h2 class="section-title">Истории наших клиентов</h2>
                <a href="#" class="comment__add">добавить свою</a>
            </div>
            <Carousel/>
        </article>

        <section class="stocks">
            <h2 class="section-title">Акции для любимых клиентов</h2>
            <div class="stocks__chapter owl-carousel owl-theme" id="stocksCarousel">
                <div class="stocks__wrapper stocks__wrapper-one">
                    <h3 class="stocks__title">Подарок: 3 л масла при ремонте двигателя на сумму от 20 000 руб</h3>
                    <p class="stocks__condition">действует до 01.01.2019</p>
                </div>
                <div class="stocks__wrapper stocks__wrapper-two">
                    <h3 class="stocks__title">Легендарная тормозная система Brembo всего за 23 500 руб</h3>
                    <p class="stocks__condition">действует до 01.01.2019</p>
                </div>
                <div class="stocks__wrapper stocks__wrapper-three">
                    <h3 class="stocks__title">Скидка 20% на любую оклейку авто плёнкой Brandname</h3>
                    <p class="stocks__condition">действует до 01.01.2019</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Form from "../form";
    import Carousel from "../Carousel";
    export default {
        name: "Service",
        components: {
            Form,
            Carousel
        }
    }
</script>

<style lang="scss" scoped>
    a {
        color: white;
    }
    .breadcrumbs__list {
        a {
            color: black;
        }
    }
    .comment__add {
        color: black;
    }
    .service-shop {
        a {
            color: white;
        }
    }
</style>