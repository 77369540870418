import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/v-home'
import diagnostika from '../components/pages/diagnostika'
import Service from '../components/pages/service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/diagnostika',
    name: 'diagnostika',
    component: diagnostika
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
